type TMessages = {
  COUNT: {
    MIN: string;
    MAX: (number) => string;
    STOCK: string;
    MAX_COUNT_TOAST: string;
    PURCAHSE_LIMIT: (number) => string;
  };
  PRODUCT: {
    [key: string]: string;
  };
  ORDER: {
    [key: string]: string | number[] | number;
  };
};

const MESSAGES: TMessages = {
  COUNT: {
    MIN: '1개 이상 구매 가능 합니다',
    MAX: (max) => `1인당 최대 ${max}개까지 구매 가능합니다.`,
    STOCK: '선택하신 수량보다 재고가 부족합니다.',
    MAX_COUNT_TOAST: '주문 가능한 최대 수량입니다.',
    PURCAHSE_LIMIT: (maxCount) =>
      `이미 장바구니에 담겨있는 상품이거나,\n동일 상품을 구매한 적이 있습니다.\n1인당 구매가능 수량 : ${maxCount}개`,
  },
  PRODUCT: {
    EXIST_OPTION: '이미 선택된 옵션 입니다.',
    OPTIONS_TEMP: '옵션을 먼저 선택해주세요.',
    ADD_CART_SUCCESS: '장바구니에 상품이 담겼습니다.',
    BUY_NOW_SUCCESS: '구매가 완료되었습니다.',
    BUY_TIMEOUT: '구매 가능한 시간이 지났습니다. 다음 수거 신청 시 구매가 가능합니다.',
    PAY_CONFIRM: '선택하신 상품을 구매하시겠습니까?\n구매 즉시 결제가 이뤄집니다.',
  },
  ORDER: {
    BILL_FAIL_CODE: [30005, 999],
    CART_NEED_SIGNIN: '로그인 후 이용해주세요.',
    CART_DELETE: '상품을 장바구니에서 삭제 하시겠습니까?',
    CART_DELETE_COMPLETE: '상품을 장바구니에서 삭제했습니다',
    CANCEL: '구매하신 상품을 취소하시겠습니까?',
    CANCEL_ALL: '구매하신 상품을 모두 취소하시겠습니까?',
    CALCEL_SUCCESS: '구매취소가 완료되었습니다.',
    SOLD_OUT: '상품이 품절되었습니다.',
    SIGN_OUT: '로그인 후 구매하실 수 있습니다.',
    WASH_ID: '수거 신청 시 함께 구매가 가능합니다.',
    NAVER_PAY_EXPIRED_CODE: 100503001,
    NAVER_PAY_EXPIRED_TITLE: '결제수단 변경 후,\n다시 시도해주세요.',
    NEED_USE_PLAN: '서비스 가입 후 이용해주세요.',
    NEED_WASH_ID: '상품 구매는 수거신청 완료 후 가능해요.',
    OVER_DEADLINE_TIME: '다음 수거신청 완료 후 구매할 수 있어요.',
    TAKE_OUT: '출고 준비가 완료되어 상품 구매가 어려워요.',
    SELL_EXPIRATION_TITLE: '구매 불가한 상품',
    SELL_EXPIRATION: '판매기간이 종료된 상품입니다.',
  },
};

export const ERROR_TYPE_TOAST: string[] = [
  '상품이 품절되었습니다.',
  '상품 최대 구매 수량을 초과하였습니다.',
];

export const STORE_PRODUCT_URL_PATH = '/store/products/';
export default MESSAGES;
